

jQuery(document).ready(function(){

   // Schedule Header scroll add sticy class
   // ===============================================
   /*jQuery(window).scroll(function(){
      var window_scrolll = $(window).scrollTop();
      if (jQuery( "div" ).hasClass( "schedule-header")) {

         if(window_scrolll > 150){
            jQuery(".content-wrapper").addClass('schedule-header-sticy');
         } else{
            jQuery(".content-wrapper").removeClass('schedule-header-sticy');
         }

      }
   });*/

   // Main menu add active class
   // ===============================================
   /*jQuery(".main-menu-wrap ul.main-menu > li > a").on( "click", function() {
     jQuery(".main-menu-wrap ul.main-menu > li").removeClass('active');
     jQuery(this).parent().addClass('active');
   });
   jQuery(".main-menu-wrap ul.main-menu ul li a").on( "click", function() {
     jQuery(".main-menu-wrap ul.main-menu > li").removeClass('active');
     jQuery(this).parents("li.has-submenu").addClass('active');
   });*/

   // Main moblie menu
   // ===============================================
   /*jQuery( "ul.main-menu > li.has-submenu" ).prepend( "<span class='menu-arrow' onclick=''></span>" );
   var window_width = jQuery(window).width();
   if (window_width < 1200) {
      jQuery("ul.main-menu li.has-submenu .menu-arrow").on("click", function(){
        jQuery(this).parent("li.has-submenu").toggleClass('has-submenu-open');
      });
   }
   if (window_width < 768) {
      jQuery(".header .menu-toggle-icon").on( "click", function() {
        jQuery("body").addClass('body-mblmenu-open');
        jQuery(this).parent(".menu-toggle").addClass('menu-toggle-active');
      });
      jQuery(".header .menu-toggle-overlay, .menu-toggle-close").on( "click", function() {
        jQuery("body").removeClass('body-mblmenu-open');
        jQuery(".header .menu-toggle").removeClass('menu-toggle-active');
      });

      // Moblie sub menu
      // ===============================================
      jQuery("ul.main-menu .has-submenu > .menu-arrow").on("click", function(){
         jQuery(this).siblings("ul.submenu").slideToggle();
      });
   }*/

   // User sidbar menu collaps
   // ===============================================
   jQuery(".usidebar-filter .usbf-btns").on( "click", function() {
     jQuery(this).parents(".usidebar-filter-item").toggleClass('usb-filter-collapsed');
     jQuery(this).parents(".usidebar-filter-item").find(".usbf-btn-collaps").slideToggle();
   });
   jQuery(".usidebar-fbtn").on( "click", function() {
     jQuery(".user-sidebar-wrep").toggleClass('user-sidebar-collapsed');
     jQuery(".user-sidebar-footer").toggleClass('usidebar-footer-collapsed');
     jQuery(".shift-datatable-spacer").toggleClass('shift-dt-spacer-collapsed');
   });
   
   // Siftbar Tab active class
   // ===============================================
   jQuery(".siftbar-tab .rtab-btn").on( "click", function() {
     jQuery(".siftbar-tab .rtab-btn").removeClass('active');
     jQuery(this).addClass('active');
   });

   // Siftbar Tab active class
   // ===============================================
   var ckbox = jQuery('.siftbar-check-wrap input.custom-control-input');
   jQuery('input.custom-control-input').on('click',function () {
      if (ckbox.is(':checked')) {
         jQuery(".sftdate-copy-wrap").addClass('active');
      } else {
         jQuery(".sftdate-copy-wrap").removeClass('active');
      }
   });

   // Schedule Tab active class
   // ===============================================
   jQuery(".schedule-tab-wrap .schedule-tab > li > a").on( "click", function() {
     jQuery(".schedule-tab-wrap .schedule-tab > li").removeClass('active');
     jQuery(this).parent("li").addClass('active');
   });   

   // Datetimepicker
   // ===============================================
   // // Only Date Picker (<input data-format="yyyy-MM-dd" type="text">)
   /*jQuery(function() {
      jQuery('.rst-date').datetimepicker({
         language: 'en',
         pickTime: false,
         inline: true,
         sideBySide: true
      });
   });

   // Only Time Picker (<input data-format="HH:mm:ss" type="text">)
   jQuery(function() {
      jQuery('.rst-time').datetimepicker({
        pickDate: false,
        pickSeconds: false,
        timeFormat:  "HH:mm"
      });
   });
*/
   // Data Table
   // ===============================================
   jQuery(document).ready(function() {
      jQuery('.datatable-list').DataTable();
   });

   // Week owl slider js
   // ===============================================


   // jQuery('.est-manage-slider').owlCarousel({
   //    items: 1,
   //    nav:true,
   //    dots:false
   // });



   // Calendar pickar
   // ===============================================
   // jQuery('.getCalendar').dcalendar();


   // Copy 2 shifts to header Ui calender
   // ===============================================
   $('#copydatep_weekpicker').datepicker({
      changeYear: true
   });
   $('#copyweekp_weekpicker').weekpicker({

   });


   // End
   // ===============================================


});

// Coppy week Calender
// ===============================================
var weekcount = getWeekCount();
var from_wc;
var to_wc;
$( "#week_count" ).change(function() {
   weekcount = getWeekCount();
   
   // Refresh both the calendars
   from_wc.weekpicker("refresh");
   from_display.text(from_wc.weekpicker("startDate"));
   from_enddisplay.text(from_wc.weekpicker("endDate"))

   to_wc.weekpicker("refresh");
   to_display.text(to_wc.weekpicker("startDate"));
   to_enddisplay.text(to_wc.weekpicker("endDate"))
   console.log(from_wc.weekpicker("endDate"));

});
function getWeekCount(){
   var str = "";
   $( "#week_count option:selected" ).each(function() {
     str += $( this ).val();
   });
   return str;
}
$(function() {
   var dateText = '05/26/2018';
   from_display = $('#week-start');
   from_enddisplay = $('#week-end');
   to_display = $('#to-week-start');
   to_enddisplay = $('#to-week-end');
  
   from_display.text(dateText);
   to_display.text(dateText);
   from_wc =   $('#cshift_weekpicker').weekpicker({
      currentText: dateText,
      onSelect: function(dateText, startDateText, startDate, endDate, inst, endDateText) {
         from_display.text(startDateText);
         from_enddisplay.text(endDateText)
      },
      restrictWeeks: []
   });
  
   to_wc =  $('#cshift_weekpicker1').weekpicker({
      currentText: dateText,
      onSelect: function(dateText, startDateText, startDate, endDate, inst, endDateText) {
         to_display.text(startDateText);
         to_enddisplay.text(endDateText)
      },
      restrictWeeks: []
   });
});

// End Coppy week Calender
// ===============================================


